import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Card, CardContent, CardMedia, Tooltip, Button, useTheme } from "@mui/material";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MenuIcon from '@mui/icons-material/Menu';

const localizer = momentLocalizer(moment);

const services = [
  {
    id: 1,
    date: new Date(), // Data de início: hoje
    endDate: moment().add(3, 'days').toDate(), // Data de término: 3 dias a partir de hoje
    title: 'Produto com rate valido',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  {
    id: 2,
    date: new Date(2024, 6, 15, 14, 0),
    endDate: new Date(2024, 6, 15, 15, 30),
    title: 'Produto com rate valido',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  // Adicione mais serviços aqui
];

const tasks = [
  {
    id: 1,
    date: new Date(), // Data de início: hoje
    endDate: moment().add(1, 'days').toDate(), // Data de término: amanhã
    title: 'Tarefa 1',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  {
    id: 2,
    date: new Date(2024, 6, 16, 10, 0),
    endDate: new Date(2024, 6, 16, 12, 0),
    title: 'Tarefa 2',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  // Adicione mais tarefas aqui
];

const resources = [
  {
    id: 1,
    date: new Date(), // Data de início: hoje
    endDate: moment().add(5, 'days').toDate(), // Data de término: 5 dias a partir de hoje
    title: 'Recurso 1',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  {
    id: 2,
    date: new Date(2024, 6, 18, 9, 0),
    endDate: new Date(2024, 6, 18, 11, 0),
    title: 'Recurso 2',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGxx1DUJNuxRai0IV8G04TKWCpkcmRlkRhA&s',
  },
  // Adicione mais recursos aqui
];

const Dashboard = () => {
  const theme = useTheme();
  const [currentView, setCurrentView] = useState('services');

  const handleDailyDepartureButton = () => {
    window.location.href = '/daily-departure';
  };

  const getCurrentEvents = () => {
    switch (currentView) {
      case 'services':
        return services.map(service => ({
          title: service.title,
          start: service.date,
          end: service.endDate,
          resource: service,
        }));
      case 'tasks':
        return tasks.map(task => ({
          title: task.title,
          start: task.date,
          end: task.endDate,
          resource: task,
        }));
      case 'resources':
        return resources.map(resource => ({
          title: resource.title,
          start: resource.date,
          end: resource.endDate,
          resource: resource,
        }));
      default:
        return [];
    }
  };

  const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        padding: '5px', // Diminua o padding para ajustar o tamanho dos cards
        border: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden' // Esconda o excesso de conteúdo
      }
    }
  };

  const EventComponent = ({ event }) => (
    <Tooltip title={event.resource.title} arrow>
      <Card style={{ width: '100%' }}>
        <CardMedia
          component="img"
          height="40" // Ajuste a altura da imagem para economizar espaço
          image={event.resource.image}
          alt={event.resource.title}
        />
        <CardContent style={{ padding: '5px' }}> {/* Diminua o padding do CardContent */}
          <Typography variant="body2" component="div">
            {event.resource.title}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
          </Typography>
        </CardContent>
      </Card>
    </Tooltip>
  );

  return (
    <Box m="20px" textAlign="center">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>    
        <Box mb={2}>
          <Button variant="contained" onClick={() => setCurrentView('services')} style={{ marginRight: '10px' }}>
            Serviços
          </Button>
          <Button variant="contained" onClick={() => setCurrentView('tasks')} style={{ marginRight: '10px' }}>
            Tarefas
          </Button>
          <Button variant="contained" onClick={() => setCurrentView('resources')}>
            Recursos
          </Button>
          <Button sx={{ marginLeft : "10px" }} variant="contained" onClick={handleDailyDepartureButton}>
            <MenuIcon/>
          </Button>
        </Box>
        <div style={{ height: '80vh', width: '100%' }}>
          <Calendar
            localizer={localizer}
            events={getCurrentEvents()}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', width: '100%' }}
            eventPropGetter={eventPropGetter}
            components={{ event: EventComponent }}
          />
        </div>
      </div>
    </Box>
  );
};

export default Dashboard;
