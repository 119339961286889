import React from "react";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <footer style={{ marginTop: "400px", textAlign: "center" }}>
      <Typography variant="body2" color="textSecondary">
        BU COUNTRY TOURS | 2023 🌴
      </Typography>
    </footer>
  );
};

export default Footer;
